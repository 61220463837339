/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Vánoce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1ji4lbi --center pb--80 pt--80" name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold; color: var(--white);\">Vánoce dětem, kterým osud nepřál.</span><span style=\"font-weight: bold; color: var(--white);\"><br></span>"}>
              </Title>

              <Title className="title-box title-box--left fs--62" content={"<span style=\"color: var(--color-dominant);\"><br><br><br><br></span><br><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" style={{"paddingBottom":41}} name={"v9dzjuf8a7j"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":399,"paddingLeft":130}} content={"<span style=\"color: var(--black); font-weight: bold;\">Vánoce se blíží. Ne každý je ale může trávit doma s rodinou a s těmi, které má rád. Sám vím, jaké to je zůstat jako dítě na Vánoce v dětském domově osamocen a právě proto jsem se rozhodl po odchodu z dětského domova dělat každý rok Vánoce dětem v dětských domovech příjemnější, hezčí a s těmi, které mají rádi.</span>"}>
              </Text>

              <Button className="btn-box" content={"<span style=\"font-weight: bold;\">DONIO.CZ</span>"} url={"Vánoce se blíží. Ne každý je ale může trávit doma s rodinou a s těmi, které má rád. Sám vím, jaké to je zůstat jako dítě na Vánoce v dětském domově osamocen a právě proto jsem se rozhodl po odchodu z dětského domova dělat každý rok Vánoce dětem v dětských domovech příjemnější, hezčí a s těmi, které mají rádi."} href={"Vánoce se blíží. Ne každý je ale může trávit doma s rodinou a s těmi, které má rád. Sám vím, jaké to je zůstat jako dítě na Vánoce v dětském domově osamocen a právě proto jsem se rozhodl po odchodu z dětského domova dělat každý rok Vánoce dětem v dětských domovech příjemnější, hezčí a s těmi, které mají rádi."}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/93d61f4b976c484f8e4b548703754a9e_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/93d61f4b976c484f8e4b548703754a9e_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/93d61f4b976c484f8e4b548703754a9e_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/93d61f4b976c484f8e4b548703754a9e_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/811133f385854d5fa1395c53b22e4c68_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/811133f385854d5fa1395c53b22e4c68_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/811133f385854d5fa1395c53b22e4c68_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/811133f385854d5fa1395c53b22e4c68_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":399,"paddingLeft":118}} content={"<span style=\"color: var(--black); font-weight: bold;\">Děti z dětských domovů si nepřejí žádné drahé dary ani cennosti. Jsou to naprosto běžná přání jako u ostatních dětí. U mladších dětí se nejčastěji jedná o hračky nebo různé hry či sportovní potřeby a u starších dětí už spíše převažují praktické dárky, které mohou využít ve svém životě i po odchodu z dětského domova.   </span>"}>
              </Text>

              <Button className="btn-box" content={"<span style=\"font-weight: bold;\">DONIO.CZ</span>"} url={"Děti z dětských domovů si nepřejí žádné drahé dary ani cennosti. Jsou to naprosto běžná přání jako u ostatních dětí. U mladších dětí se nejčastěji jedná o hračky nebo různé hry či sportovní potřeby a u starších dětí už spíše převažují praktické dárky, které mohou využít ve svém životě i po odchodu z dětského domova.   "} href={"Děti z dětských domovů si nepřejí žádné drahé dary ani cennosti. Jsou to naprosto běžná přání jako u ostatních dětí. U mladších dětí se nejčastěji jedná o hračky nebo různé hry či sportovní potřeby a u starších dětí už spíše převažují praktické dárky, které mohou využít ve svém životě i po odchodu z dětského domova.   "}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":0}} name={"f237c73nec8"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":399,"paddingLeft":150}} content={"<span style=\"color: var(--black); font-weight: bold;\">Pomůžeme dětem v dětských ústavních zařízení po celé České republice. Velmi často se jedná o dětí, které prošly a procházejí velmi složitým životním obdobím i přes svůj velmi nízký věk. Konkrétní příklady dětí, kterým plníme přání: Dívka (13 let), druhým rokem v dětském domově, zneužívaná a týraná vlastním otcem. Chlapec (10 let), třetím rokem v dětském domově, týraný a zanedbávaný vlastními rodiči. a řada dalších … </span>"}>
              </Text>

              <Button className="btn-box" content={"<span style=\"font-weight: bold;\">DONIO.CZ</span>"} url={"https://donio.cz/vanoce-detem-kterym-osud-nepral"} href={"https://donio.cz/vanoce-detem-kterym-osud-nepral"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/a0399f09f62540628b9a06f8e84442dc_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/a0399f09f62540628b9a06f8e84442dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/a0399f09f62540628b9a06f8e84442dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/a0399f09f62540628b9a06f8e84442dc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/a0399f09f62540628b9a06f8e84442dc_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/028e2e91ecbf4521b520d5daeca0a973_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/028e2e91ecbf4521b520d5daeca0a973_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/028e2e91ecbf4521b520d5daeca0a973_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/028e2e91ecbf4521b520d5daeca0a973_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/028e2e91ecbf4521b520d5daeca0a973_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">Zapojené dětské domovy:\n<br>&nbsp;<br>DD Nová Ves u Chotěboře, DDÚ Hradec Králové,<br>DD Mladá Boleslav, DD Sedloňov, DDŠ Místo,<br>DD Krásná Lípa, DD Vizovice, DD Zašová, DD Litovel,<br>DD Černá Voda, DD Tisá,&nbsp; DD Rovečné,&nbsp; DD Prostějov\n<br><br>&nbsp;a řada dalších …  </span>"}>
              </Text>

              <Button className="btn-box" content={"<span style=\"font-weight: bold;\">DONIO.CZ</span>"} url={"https://donio.cz/vanoce-detem-kterym-osud-nepral"} href={"https://donio.cz/vanoce-detem-kterym-osud-nepral"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1t8j3a9 css-42e4bw pb--80 pt--80" name={"ifcwoxmpjm"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"<span style=\"font-weight: bold; color: var(--color-blend--95);\">Vánoce pro dětské domovy <br>v České televizi.&nbsp;</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 btn-box--filling2 btn-box--left" url={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/223411010101109/cast/1010348/"} href={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/223411010101109/cast/1010348/"} content={"<span style=\"font-weight: bold; color: var(--color-blend--95);\">reportáž zde</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"xohgb9jkxib"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"7qp1wa3hfmi"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>

      </Layout>
    </ThemeWrapper>
  )
}